//공통 사항
.title {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 20px;
	color: #3c256c;
}

.summary {
	font-weight: 300;
	color: #333;
	margin-top: 0;
	margin-bottom: 30px;
	word-break: keep-all;
}
label {
	font-size: 14px;
	font-weight: 500;
	color: #333;
	line-height: 20px;
}
input[type="text"],
input[type="password"] {
	width: 100%;
	padding: 0px 11px 1px 15px;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	outline: none;
	box-sizing: border-box;
	font-size: 14px;
	margin-bottom: 5px;
	box-sizing: border-box;
	height: 41px;
	border: 1px solid #dddddd;
	border-radius: 3px;
	background-color: #ffffff;

	&:focus {
		border: 1px solid rgb(51, 51, 51);
	}
	&::placeholder {
		color: #545454;
		font-size: 13px;
	}
}
.input-wrap {
	margin-bottom: 15px;
	label {
		text-align: left;
		width: 100%;
		font-size: 16px;
		cursor: pointer;

		input[type="checkbox"] {
			width: 20px;
			height: 20px;
			margin-right: 5px;
			vertical-align: bottom;
		}
	}
}
button {
	display: block;
	padding: 0px 10px;
	width: 100%;
	height: 44px;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 18px;
	color: #ffffff;
	cursor: pointer;
	text-align: center;
	overflow: hidden;
	border-radius: 3px;
	border: 0px none;
	text-transform: capitalize;

	&.passkey-login,
	&.passkey-register,
	&.register {
		background-color: #673ab7;
	}

	&.login,
	&.check-code {
		background-color: #2b2b2b;
	}

	&.logout,
	&.go-back,
	&.cancle {
		background-color: #ebebeb;
		color: #373737;
	}
	&:disabled {
		cursor: unset;
		background: rgba(118, 118, 118, 0.3) !important;
	}

	&.email-withdraw {
		background-color: #fff !important;
		color: #222;
		text-align: right;
		font-weight: 300;
		margin: 5px 0;
		height: 20px;
	}
	& + button {
		margin-top: 15px;
	}
}

a {
	// font-size: 13px;
	color: rgb(51, 51, 51);
	cursor: pointer;
	letter-spacing: -0.6px;
	text-decoration: none;
	font-weight: bold;
}

.qr-container {
	border: 1px solid #673ab7;
	padding: 20px 0;
	margin-bottom: 15px;
}
.passkey-container {
	max-width: 382px;
	width: 100%;
}

// 로그인 페이지
.login-container {
	button {
		width: 100%;

		&.fake-btn {
			background-color: #dbdbdb !important;
			color: #838383;
			font-size: 16px;
			font-weight: 400;
			margin: 15px 0;
			height: 27px;
		}
	}
	p {
		margin-top: 15px;
		font-weight: 100;
		font-size: 13px;
	}
	.login-sub {
		text-align: left;
		margin-bottom: 20px;
		p {
			font-weight: 400;
			font-size: 13px;
			line-height: 1.5rem;
			margin: 0;
			color: #333;
			&:last-child {
				margin-top: 15px;
			}
		}
	}
	.or-line {
		display: flex;
		align-items: center;
		margin: 10px 0;
		span {
			height: 1px;
			width: 100%;
			background: #000;
		}
		p {
			margin: 0;
			padding: 0 10px;
		}
	}
}

// 웰컴페이지
.welcome-container {
	button {
		margin-top: 10px;
	}
	.userinfo {
		text-align: left;
		border: 1px solid #333;
		padding: 10px 20px;
		margin-bottom: 15px;
		p {
			margin-bottom: 10px;
			span {
				font-weight: 300;
			}
		}
	}
}

.passkey-login-container {
	button {
		&:last-child {
			margin-top: 10px;
		}
	}
}

// 가입 페이지
.join-container {
	.join-sub {
		text-align: left;
		margin-bottom: 26px;

		p {
			margin: 0;
			color: #333;
			word-break: keep-all;
			font-size: 13px;
			letter-spacing: -0.2px;
			line-height: 15px;

			&:last-child {
				margin-top: 15px;
			}
			span {
				color: red;
			}
		}
	}

	.join-inputs {
		display: flex;
		flex-direction: column;
		text-align: left;
		div {
			width: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;

			label {
				width: 170px;
			}
		}
	}
}

// layerpopup

.layerpopup-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;

	&.open {
		display: block;
	}
	&.close {
		display: none;
	}

	.layerpopup-wrap {
		display: block;
		max-width: 380px;
		width: 80%;

		border-radius: 0.5rem;
		background: #fff;
		padding: 1.5rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		.title {
			font-size: 16px;
			margin-bottom: 38px;
		}
		p {
			text-align: center;
			font-size: 15px;
			font-weight: 500;
			word-break: break-all;
			margin-top: 0;
			margin-bottom: 45px;
			word-break: break-word;
		}
		button {
			display: inline-block;
			width: 140px;
			font-weight: 500;
			font-size: 14px;
			height: 40px;
			margin-bottom: 0;
			background-color: #3c256c;
			text-align: center;
		}
	}
	.popup-bg {
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		position: fixed;
		top: 0;
		left: 0;
	}
}
